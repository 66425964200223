import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  LPlan,
  CSectTitle,
  CSimpleAccordionList,
  CDefinition,
  CBtnList,
  CPostCard02,
  CBorderTitle,
  CSpTabTable,
  CSliderMedia,
  CTab,
  CTabItem,
} from '../../../components/_index';
import planChoice from '../../../utils/plan-choice';
import planGet from '../../../utils/plan-get';
import '../../../assets/_sass/page/aesthetic.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            en: 'aeshetic plan',
            ja: 'エステプラン',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/aesthetic/menu/kv.png',
              },
              imgSp: {
                src: '/assets/images/aesthetic/menu/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: 'エステプラン',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <h2 className="c_headingLv2">
            リゾートホテルを満喫できる
            <br />
            女性にうれしいエステプラン
          </h2>
          <p className="c_sectLead u_mbMedium">
            アーユソーマでは、スイーツセット付きプランや記念日に最適なプラン、ブライダルエステプランなどもご用意しております。
            <br />
            お客様のシーンにあわせてご利用ください。
          </p>
          <CPostCard02 data={planChoice(planGet(), ['エステ'], 0)} />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <section className="l_sect u_bgGray">
        <LWrap>
          <CSectTitle
            title={{
              ja: '宿泊者限定特典',
              en: (
                <>
                  GUEST
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  BENEFITS
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマではお気軽にエステをご利用いただけるよう、
            <br />
            ご宿泊者限定の特典をご用意しております。
          </p>

          <div
            className="u_bgWhite c_simpleAccordionList aesthetic"
            style={{ padding: '56px', border: 'none' }}
          >
            <CBorderTitle
              size="small"
              title={<>宿泊者限定特典</>}
              exClass="n_mt"
            />
            <p className="title">全グランドメニュー20%OFF</p>
            <p className="text">
              ご宿泊のお客様（女性限定）は、アーユソーマの全グランドメニューを「20％OFF」でご利用いただけます。
            </p>
            <CBtnList
                data={[
                  {
                    label: 'グランドメニューはこちら',
                    link: {
                      href: '/assets/files/pdf/ayu_grandmenu.pdf',
                    },
                  },
                ]}
              />

            <p className="sp_title u_sp u_pt40">エステ60分8,800円</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic12.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">エステ60分8,800円</p>
                      <p className="text">
                        ご宿泊のお客様（女性のお客様限定）は、「エステ60分8,800円」をご利用いただけます。適応条件など、詳しくは下記からご確認ください。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/wbqz26fda/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />

            <p className="sp_title u_sp u_pt40">宿泊者限定アニバーサリーエステプラン</p>
            <CSliderMedia
              reverse={true}
              data={[
                {
                  img: [
                    {
                      img: {
                        src: '/assets/images/aesthetic/img_aesthetic13.png',
                        alt: 'エステ',
                      },
                    },
                  ],
                  title: <></>,
                  text: (
                    <>
                      <p className="title">宿泊者限定アニバーサリーエステプラン</p>
                      <p className="text">
                      お誕生日や結婚記念日など、一年に一度のアニバーサリーを贅沢な気分でお過ごしいただけるようご宿泊者限定のアニバーサリーエステプランをご用意しております。
                      </p>
                    </>
                  ),
                  btn: {
                    link: {
                      href: '/aesthetic/lhqkeot5ya3p/',
                    },
                    label: '詳しくはこちら',
                  },
                },
              ]}
            />
          </div>
        </LWrap>
      </section>
      <section className="l_sect">
        <LWrap>
          <CSectTitle
            title={{
              ja: '営業時間｜ご利用案内',
              en: <>GUIDE</>,
            }}
          />
          <CDefinition
            data={[
              {
                title: <>場所</>,
                text: <>仙台ロイヤルパークホテル3F</>,
              },
              {
                title: <>営業時間</>,
                text: <>
                日曜日 ～ 木曜日 ／ 10:00～19:30
                <br />
                金曜日・土曜日・祝前日 ／ 10:00～21:00
                </>,
              },
              {
                title: <>ご予約について</>,
                text: (
                  <>
                    事前予約制
                  </>
                ),
              },
              {
                title: <>対象者</>,
                text: (
                  <>
                    18歳以上の女性の方
                    <ul className="c_noteList">
                      <li>
                        ペアトリートメントプランのみ、男女でのご利用が可能です。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>会員特典</>,
                text: (
                  <>
                    「ザ
                    クラブ・ロイヤルパークホテルズ」会員の方は、一般価格から5%OFFの会員優待価格にてご利用いただけます。会員ステージに応じたポイントも付与されます。
                  </>
                ),
              },
              {
                title: <>禁忌事項</>,
                text: (
                  <>
                    妊娠・心臓疾患・循環器系障害・静脈瘤・皮膚疾患・体調不良、その他持病のある方や医師による治療を受けている方は、トリートメントをお受けいただけませんのでご了承ください。
                    <br />
                    また、アルコール摂取後のご利用もお控えいただきますようお願いいたします。詳しくはお問い合わせください。
                  </>
                ),
              },
              {
                title: <>キャンセル料</>,
                text: (
                  <>
                    ご予約の変更、キャンセルにつきましては前日18時までにご連絡ください。
                    <ul className="c_noteList">
                      <li>
                        当日キャンセルの場合、キャンセル料として50%を頂戴します。
                      </li>
                    </ul>
                  </>
                ),
              },
              {
                title: <>お問合せ</>,
                text: (
                  <>
                    <a href="tel:0223773896">022-377-3896</a>
                    <br />
                    （受付時間10:00～18:00）
                  </>
                ),
              },
            ]}
          />
          {/* <CBtnList
            data={[
              {
                label: <>オンライン予約</>,
                link: {
                  href: '#',
                  blank: true,
                },
              },
            ]}
          /> */}
        </LWrap>
      </section>
      <section
        id="pre-paid-ticket"
        className="l_sect c_simpleAccordionList aesthetic"
      >
        <LWrap>
          <CSectTitle
            title={{
              ja: 'プリペイドチケット',
              en: (
                <>
                  PRE-PAID
                  <span className="u_pc"> </span>
                  <br className="u_sp" />
                  TICKET
                </>
              ),
            }}
          />
          <p className="c_sectLead u_mbMedium">
            アーユソーマでは、お得なプリペイドチケットをご用意しております。
            <br />
            継続してご利用いただきやすいプリペイドチケットをご活用ください。
          </p>
          <div className="u_pc">
            <CSpTabTable
              data={{
                header: ['Aプラン', 'Bプラン', 'Cプラン'],
                key: ['料金', '有効期間', '優待①', '優待②'],
                items: [
                  {
                    content: [
                      '150,000円',
                      '2年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      'グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）',
                    ],
                  },
                  {
                    content: [
                      '75,000円',
                      '1年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      <>
                        エステオプション「パーツトリートメント」プレゼント
                        <div className="note">30分：5,000円相当</div>
                      </>,
                    ],
                  },
                  {
                    content: [
                      '45,000円',
                      '半年間',
                      '全グランドメニューをプリペイドチケット価格で利用可能',
                      '-',
                    ],
                  },
                ],
              }}
            />
          </div>
          <div className="u_sp tab">
            <CTab defaultKey={0}>
              <CTabItem title="Aプラン" tabKey={0}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">150,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">2年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    グランドメニュー60分（18,000円コース相当）の1回分無料チケットプレゼント（ご本人様もしくはご家族・ご友人の方へ譲渡可能）
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Bプラン" tabKey={1}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">75,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">1年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">
                    エステオプション「パーツトリートメント」プレゼント
                    <div className="note">30分：5,000円相当</div>
                  </p>
                </div>
              </CTabItem>
              <CTabItem title="Cプラン" tabKey={2}>
                <div className="tab_item">
                  <p className="title">料金</p>
                  <p className="price">45,000円</p>
                </div>
                <div className="tab_item">
                  <p className="title">有効期間</p>
                  <p className="price">半年間</p>
                </div>
                <div className="tab_item">
                  <p className="title">優待①</p>
                  <p className="price">
                    全グランドメニューをプリペイドチケット価格で利用可能
                  </p>
                </div>
                <div className="tab_item">
                  <p className="title">優待②</p>
                  <p className="price">-</p>
                </div>
              </CTabItem>
            </CTab>
          </div>
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
